import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { fetchPOST } from '../apiHelper';
import { addVolitve } from '../listOfApi';

/*React-Bootstrap component */
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

const AddVolitve = () => {
  const history = useHistory();

  const [volitveData, setVolitveData] = useState({
    naziv: '',
    datum: '',
  });
  const [vnosError, setVnosError] = useState(false);

  const handleSubmitForm = (e) => {
    e.preventDefault();

    fetchPOST(addVolitve, volitveData).then((volitve) => {
      if (volitve === 1) {
        history.goBack();
      } else {
        //TODO: handle error!
        setVnosError(true);
      }
    });
  };

  const handleFormData = (e) => {
    setVnosError(false);
    setVolitveData({ ...volitveData, [e.target.name]: e.target.value });
  };

  console.log(volitveData);

  return (
    <Container fluid>
      <>
        <Row>
          <Col className='main-container-header'>Dodaj volitve</Col>
          <Col>
            <Link to={'/admin'}>
              <Button className='mt-4 float-right main-container-navigation-button'>
                Nazaj na seznam volitev
              </Button>
            </Link>
          </Col>
        </Row>
        {vnosError && (
          <Row className='mt-5'>
            <Col>
              <h3 className='text-danger'>
                Prišlo je do napake pri vnosu volitev!
              </h3>
            </Col>
          </Row>
        )}
        <Row className='mt-4'>
          <Col>
            <Row className='d-flex align-items-center'>
              <Col className='mt-3'>
                <Form onSubmit={handleSubmitForm}>
                  <Form.Group controlId='formNaziv'>
                    <Row>
                      <Col>
                        <Form.Label>Naziv volitev</Form.Label>
                        <Form.Control
                          name='naziv'
                          type='text'
                          required
                          minLength='3'
                          maxLength='200'
                          value={volitveData.naziv}
                          onChange={handleFormData}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Datum izvedbe</Form.Label>
                        <Form.Control
                          name='datum'
                          type='date'
                          required
                          value={volitveData.datum}
                          onChange={handleFormData}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Row className='mt-4'>
                    <Col>
                      <Button variant='primary' type='submit'>
                        Dodaj
                      </Button>
                      <Button
                        className='main-container-navigation-button ml-3'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Prekliči
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default AddVolitve;
