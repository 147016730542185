import React from 'react';
import { getUserName, logoutFromApp } from '../adalConfig';

/* React-bootstrap komponente */
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

/* Logo import*/
import logo from './logo.png';

const Header = () => {
  const getFirstletters = (username) => {
    let splitUsername = username.split(/[.@]/, 2);
    let FirstLettersOfUsername = (
      splitUsername[0].charAt(0) + splitUsername[1].charAt(0)
    ).toUpperCase();
    return FirstLettersOfUsername;
  };

  return (
    <Col>
      <Navbar fixed='top' className='header'>
        <Navbar.Brand className='header-brand'>
          <img
            src={logo}
            height='30'
            className='d-inline-block align-top'
            alt='logo univerza'
          />
        </Navbar.Brand>

        <Navbar.Toggle />
        <Navbar.Collapse className='justify-content-end'>
          <Navbar.Text>
            <Nav.Link
              onClick={logoutFromApp}
              title='Odjava'
              className='p-0 navbar-text'
            >
              {getFirstletters(getUserName())}
            </Nav.Link>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </Col>
  );
};

export default Header;
