import React from 'react';
import { Route as AuthorizedRoute, Route, Switch } from 'react-router-dom';

/*Component Import*/
import Admin from './Admin';
import AdminVolitveControlPanel from './AdminVolitveControlPanel';
import AddVolitve from './AddVolitve';
import NotFound from './NotFound';

/*React-bootstrap components */
import Col from 'react-bootstrap/Col';

const AuthorisationComponent = ({ role }) => {
  let routes;
  switch (role) {
    case 'uporabnik':
      routes = (
        <Switch>
          <Route component={NotFound} />
        </Switch>
      );
      break;
    case 'admin':
      routes = (
        <Switch>
          <AuthorizedRoute
            exact
            path='/'
            render={(props) => <Admin {...props} role={role} />}
          />
          <AuthorizedRoute
            exact
            path='/admin'
            render={(props) => <Admin {...props} role={role} />}
          />
          <AuthorizedRoute
            exact
            path='/admin/volitve/:id'
            render={(props) => (
              <AdminVolitveControlPanel {...props} role={role} />
            )}
          />
          <AuthorizedRoute
            exact
            path='/admin/dodaj-volitve'
            render={(props) => <AddVolitve {...props} role={role} />}
          />
          <Route component={NotFound} />
        </Switch>
      );
      break;
    default:
      break;
  }
  return <Col>{routes}</Col>;
};

export default AuthorisationComponent;
