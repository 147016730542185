/*Seznam api klicev*/

/* DELETE  --> Brisanje volitev na podlagi ID*/
export const deleteVolitve = `${process.env.REACT_APP_APIDOMAIN}volitve`;

/* POST  --> Shrani nove volitve */
export const addVolitve = `${process.env.REACT_APP_APIDOMAIN}volitve`;

/* POST  --> Uvoz volilnega imena za volitve glede na volitve ID */
export const postVolitveImenik = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik`;

/* GET  --> Pridobivanje volitve - ATKUALNE*/
export const getVolitveListAktualne = `${process.env.REACT_APP_APIDOMAIN}volitve/aktualne`;

/* GET  --> Pridobivanje volitve - PRETEKLE*/
export const getVolitveListPretekle = `${process.env.REACT_APP_APIDOMAIN}volitve/pretekle`;

/* GET  --> Pridobimo title od volitev na podlagi ID */
export const getTitleFromVolitve = `${process.env.REACT_APP_APIDOMAIN}volitve`;

/* GET  --> Pridobimo revizijske podatke na podlagi ID volitev */
export const getRevizijaData = `${process.env.REACT_APP_APIDOMAIN}volitve/revizija`;

/* GET  --> Sproži: Pridobi podatke za uporabnika na podlagi ID */
export const getUserData = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik`;

/* GET  --> pridobi število v oseb volilnem imeniku za določene volitve  */
export const getCountForVolitve = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik`;

/* GET  --> Izvov volilnega imenika TABELA Z hashi! - simplyvoting */
export const exportHashTableSimplyvoting = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik`;

/* GET  --> Izvov volilnega imenika TABELA Z hashi! - kontrolni */
export const exportHashTableKontrolni = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik`;

/* GET  --> Sproži: Dodeli ID na podlagi UPN za študente */
export const getIdForStudents = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik/student-id`;

/* GET  --> Sproži: Dodeli hash za študente */
export const getHash = `${process.env.REACT_APP_APIDOMAIN}volilni-imenik`;
