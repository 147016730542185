import { AuthenticationContext } from 'react-adal';

export const adalSettings = {
  tenant: 'adfs',
  cacheLocation: 'localStorage',
  instance: process.env.REACT_APP_ADFS_INSTANCE,
  clientId: process.env.REACT_APP_ADFS_CLIENTID,
  endpoints: {
    api: process.env.REACT_APP_ADFS_CLIENTID,
  },
};

export const authContext = new AuthenticationContext(adalSettings);

export const getUserName = () => authContext.getCachedUser().userName;

export const getToken = () =>
  authContext.getCachedToken(authContext.config.clientId);

export const logoutFromApp = () => authContext.logOut();

export const checkErorr = () => console.log(authContext.getLoginError());
