import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

/*Import Api helpers */
import { getVolitveListAktualne, getVolitveListPretekle } from "../listOfApi";
import { fetchGET } from "../apiHelper";

/*React-Bootstrap component */
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

/* Import React-icons */
import { BiDetail } from "react-icons/bi";

const Admin = ({ role }) => {
  let history = useHistory();

  const [volitveListAktualne, setVolitveListAktualne] = useState([]);
  const [volitveListPretekle, setVolitveListPretekle] = useState([]);
  const [showAtkualne, setShowAktualne] = useState(true);

  useEffect(() => {
    fetchGET(getVolitveListAktualne).then((volitve) => {
      if (volitve !== 0) {
        setVolitveListAktualne(volitve);

        if (volitve.length === 0) {
          //TODO: izpiši da je seznam prazen!
        }
      }
    });
  }, []);

  const handlePretekleVolitve = () => {
    setShowAktualne(false);
    if (volitveListPretekle.length === 0) {
      fetchGET(getVolitveListPretekle).then((volitve) => {
        if (volitve !== 0) {
          setVolitveListPretekle(volitve);

          if (volitve.length === 0) {
            //TODO: izpiši da je seznam prazen!
          }
        }
      });
    }
  };

  return (
    <Container fluid>
      <>
        <Row>
          <Col className="main-container-header">Seznam eVolitev</Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Button
              className="main-container-button-no-border mr-1"
              onClick={() => setShowAktualne(true)}
            >
              Aktualne
            </Button>
            {"|"}
            <Button
              className="main-container-button-no-border ml-1"
              onClick={handlePretekleVolitve}
            >
              Pretekle
            </Button>
          </Col>
          <Col>
            <Link to={"/admin/dodaj-volitve"}>
              <Button className="float-right main-container-navigation-button">
                Dodaj volitve
              </Button>
            </Link>
          </Col>
        </Row>
        <hr></hr>
        {showAtkualne && (
          <>
            <>
              {volitveListAktualne.length === 0 && (
                <Row>
                  <Col>
                    <h4 className="mt-2">Trenutno ni aktualnih volitev!</h4>
                  </Col>
                </Row>
              )}
            </>
            <>
              {volitveListAktualne.map((volitev, index) => {
                return (
                  <Row className="mt-4" key={index}>
                    <Col>
                      <Card>
                        <Card.Body>
                          <Row className="d-flex align-items-center">
                            <Col>
                              <Row>
                                <Col>{volitev.naziv}</Col>
                              </Row>
                              <Row>
                                <Col className="main-container-date-text__small">
                                  {new Date(volitev.datum).toLocaleDateString()}
                                </Col>
                              </Row>
                            </Col>
                            <Link to={`/admin/volitve/${volitev.volitveID}`}>
                              <Button className="float-right mr-2">
                                Podrobnosti
                              </Button>
                            </Link>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                );
              })}
            </>
          </>
        )}
        {!showAtkualne && (
          <>
            {volitveListPretekle.map((volitev, index) => {
              return (
                <Row className="mt-4" key={index}>
                  <Col>
                    <Card>
                      <Card.Body>
                        <Row className="d-flex align-items-center">
                          <Col>
                            <Row>
                              <Col>{volitev.naziv}</Col>
                            </Row>
                            <Row>
                              <Col className="main-container-date-text__small">
                                {new Date(volitev.datum).toLocaleDateString()}
                              </Col>
                            </Row>
                          </Col>
                          <Link to={`/admin/volitve/${volitev.volitveID}`}>
                            <Button className="float-right mr-2">
                              Podrobnosti
                            </Button>
                          </Link>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              );
            })}
          </>
        )}
      </>
    </Container>
  );
};

export default Admin;
