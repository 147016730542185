import { getToken } from "./adalConfig";

export const fetchGET = async (url) => {
  const settings = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      const getData = await response.json();
      return getData;
    } else {
      return 0;
    }
  } catch (e) {
    console.log(e);
  }
};

export const fetchPOST = async (url, data) => {
  const settings = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    body: JSON.stringify(data),
  };
  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      return 1;
    } else {
      return 0;
    }
  } catch (e) {
    return e;
  }
};

export const fetchDELETE = async (url) => {
  const settings = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
  try {
    const response = await fetch(url, settings);
    if (response.status === 200) {
      return 1;
    } else {
      return 0;
    }
  } catch (e) {
    console.log(e);
  }
};
