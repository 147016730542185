import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

/* Import API */
import {
  getTitleFromVolitve,
  getHash,
  exportHashTableSimplyvoting,
  exportHashTableKontrolni,
  getUserData,
  getRevizijaData,
  deleteVolitve,
  postVolitveImenik,
  getCountForVolitve,
} from "../listOfApi";
import { fetchGET, fetchDELETE, fetchPOST } from "../apiHelper";
import { CSVDownloader, CSVReader } from "react-papaparse";

/*React-Bootstrap component */
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
/*import React-Icon */
import {
  AiOutlineCheckCircle,
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import { FaFileCsv } from "react-icons/fa";
import { SiMicrosoftexcel } from "react-icons/si";

const buttonRef = React.createRef();

const AdminVolitveControlPanel = (props) => {
  const { id } = useParams();

  const [loadingSuccesID, setLoadingSuccesID] = useState(false);
  const [loadingSuccesHash, setLoadingSuccesHash] = useState(false);
  const [loadingHash, setLoadingHash] = useState(false);
  const [errorHash, setErrorHash] = useState(false);
  const [hashedTableData, setHashedTableData] = useState([]);
  const [hashedTableDataKontrolni, setHashedTableDataKontrolni] = useState([]);
  const [hashedTableDataError, setHashedTableDataError] = useState(false);
  const [showDownloadFileButton, setShowDownloadFileButton] = useState(false);
  const [showDownloadFileButtonKontrolni, setShowDownloadFileButtonKontrolni] =
    useState(false);
  const [revisionData, setRevisionData] = useState([]);

  const [volitveTitle, setVolitveTitle] = useState("Volitve");
  const [idVolilec, setIdVolilec] = useState("");
  const [hashedId, setHashedId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [csvData, setCsvData] = useState([]);
  const [databaseDataCount, setDatabaseDataCount] = useState({});
  const [loadingUvoz, setLoadingUvoz] = useState(false);
  const [loadingUvozError, setLoadingUvozError] = useState(false);
  const [numberOfHashed, setNumberOfHashed] = useState(0);
  const [displayHashButton, setDisplayHashButton] = useState(true);

  useEffect(() => {
    if (loadingSuccesID === true || loadingSuccesHash === true) {
      fetchGET(`${getCountForVolitve}/${id}/stevilo-volilcev`).then(
        (steviloVolilcev) => {
          setDatabaseDataCount(steviloVolilcev);
        }
      );
    }
  }, [loadingSuccesID, loadingSuccesHash]);

  //Pridobimo naziv volitev  iz baze.
  useEffect(() => {
    fetchGET(`${getTitleFromVolitve}/${id}`).then((title) => {
      if (title !== 0) {
        setVolitveTitle(title.naziv);
      }
    });
  }, []);

  //Pridobimo število zapisov v volilnem imeniku za volitve.
  useEffect(() => {
    fetchGET(`${getCountForVolitve}/${id}/stevilo-volilcev`).then(
      (steviloVolilcev) => {
        setDatabaseDataCount(steviloVolilcev);
      }
    );
  }, []);

  //Sproži se ob spremebi podatkov v spremenljivki csvData
  useEffect(() => {
    if (csvData.length > 0) {
    }
  }, [csvData]);

  const checkNumberOfHashed = () => {
    const apiInterval = setInterval(() => {
      fetchGET(`${getHash}/${id}/check`).then((response) => {
        if (response !== 0) {
          setNumberOfHashed(response.num);
          if (response.num === databaseDataCount.st_id) {
            clearInterval(apiInterval);
          }
        }
      });
    }, 1000); // 10 seconds in milliseconds
  };

  const exportToXlsx = (csvData) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(hashedTableDataKontrolni);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, "konrolni-izvoz_" + id + fileExtension);
  };

  //Shranjevanje podatkov v podatkovno bazo
  const handleUvozData = () => {
    setLoadingUvoz(true);
    const csv = {
      data: JSON.stringify(csvData),
    };

    fetchPOST(`${postVolitveImenik}/${id}/uvoz`, csv).then((vnosImenika) => {
      if (vnosImenika === 1) {
        setLoadingUvoz(false);
        fetchGET(`${getCountForVolitve}/${id}/stevilo-volilcev`).then(
          (steviloVolilcev) => {
            if (steviloVolilcev.st_uvoz !== 0) {
              setDatabaseDataCount(steviloVolilcev);
            }
          }
        );
      } else {
        setCsvData([]);
        setLoadingUvoz(false);
        setLoadingUvozError(true);

        setTimeout(() => {
          setLoadingUvozError(false);
        }, 5000);
      }
    });
  };

  //Upravljanje z importom podatkov
  const handleOpenDialog = (e) => {
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  //Upravljanje z importom podatkov po tem ko jih uspešno pridobimo iz csv datoteke.
  const handleOnFileLoad = (volilniImenikData) => {
    //odstranimo header
    volilniImenikData.shift();

    const urejenVolilniImenik = volilniImenikData.map((value) => {
      return {
        UPN: value.data[0] || "",
        VoterID: value.data[1] || 0,
        TeamName: value.data[2] || "",
        TeamGUID: value.data[3] || "",
      };
    });
    setCsvData(urejenVolilniImenik);
  };

  //Upravljanje z podatki v primeru napake pri importu CSV podatkov
  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  //Upravljanje z podatki v primeru odstranjevanja CSV datoteke.
  const handleOnRemoveFile = (data) => {
    setCsvData([]);
  };

  //Upravljanje odstranjevanja .CSV datoteke
  const handleRemoveFile = (e) => {
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  //Dodeljevnaje Hash na podlagi ID za volilni imenik
  const handleAddHash = () => {
    setLoadingSuccesHash(false);
    setErrorHash(false);
    setLoadingHash(true);
    setDisplayHashButton(false);
    setNumberOfHashed(0);

    //Sproti preverjaj število hashiranih vnosov
    checkNumberOfHashed();

    fetchGET(`${getHash}/${id}/voter-hash`).then((response) => {
      if (response !== 0) {
        setLoadingSuccesHash(true);
      } else {
        setErrorHash(true);
      }
      setLoadingHash(false);
    });
  };

  //Pridobivanje hash podatkov volilnega imenika in njihov izvoz v .CSV datoteko
  const handleExportCSVsimplyvoting = () => {
    setHashedTableDataError(false);
    fetchGET(`${exportHashTableSimplyvoting}/${id}/podatki-izvoz`).then(
      (tableData) => {
        if (hashedTableData !== 0 && tableData.length > 0) {
          setHashedTableData(tableData);
          setShowDownloadFileButton(true);
        } else {
          setHashedTableDataError(true);
        }
      }
    );
  };

  const handleExportCSVKontrolni = () => {
    setHashedTableDataError(false);
    fetchGET(`${exportHashTableKontrolni}/${id}/kontrolni-izvoz`).then(
      (tableData) => {
        if (hashedTableDataKontrolni !== 0 && tableData.length > 0) {
          setHashedTableDataKontrolni(tableData);
          setShowDownloadFileButtonKontrolni(true);
        } else {
          setHashedTableDataError(true);
        }
      }
    );
  };

  //Pridobivanje hashiranega podatka na podlagi ID volilca
  const handleGetUserData = () => {
    fetchGET(`${getUserData}/${id}/uporabnik-podatki/${idVolilec}`).then(
      (hash) => {
        if (hash !== 0) {
          setHashedId(hash);
        } else {
        }
      }
    );
  };

  //Pridobivanje revizijskih podatkov za volitve
  const handleGetRevisionData = () => {
    fetchGET(`${getRevizijaData}/${id}`).then((revizija) => {
      if (revizija !== 0) {
        setRevisionData(revizija);
      } else {
      }
    });
  };

  //Brisanje vseh podatkov (volilni imenik, šifrirani podatki) za volitve
  const handleDataDelete = () => {
    setShowModal(true);
    fetchDELETE(`${deleteVolitve}/${id}`).then((deleted) => {
      if (deleted === 1) {
        setShowModal(false);
        fetchGET(`${getCountForVolitve}/${id}/stevilo-volilcev`).then(
          (steviloVolilcev) => {
            if (steviloVolilcev.st_id === 0) {
              setDatabaseDataCount(steviloVolilcev);
              setCsvData([]);
              setLoadingSuccesHash(false);
              setErrorHash(false);
              setDisplayHashButton(true);
            }
          }
        );
      } else {
        //TODO: prišlo je do napake pri brisanju podatkov.
      }
    });
  };

  return (
    <Container fluid>
      <Row className="mt-4 mb-4">
        <Col className="main-container-header">{volitveTitle}</Col>
        <Col>
          <Link to={"/"}>
            <Button className="mt-4 float-right main-container-navigation-button">
              Nazaj na seznam volitev
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col className="main-container-text-small">Korak 1</Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col lg={2}> Uvoz volilnega imenika </Col>

                {loadingUvoz && (
                  <span>
                    <Spinner animation="grow" className="mr-3" />
                    Shranjevanje podatkov...
                  </span>
                )}
                {loadingUvozError && (
                  <span className="mr-5 text-danger">
                    {" "}
                    Prišlo je do napake pri uvozu volilnega imenika!
                  </span>
                )}
                {!loadingUvoz && (
                  <>
                    {databaseDataCount.st_id === 0 && (
                      <>
                        <Col lg={5}>
                          <CSVReader
                            ref={buttonRef}
                            onFileLoad={handleOnFileLoad}
                            onError={handleOnError}
                            noClick
                            noDrag
                            onRemoveFile={handleOnRemoveFile}
                            config={{
                              skipEmptyLines: true,
                            }}
                          >
                            {({ file }) => (
                              <aside
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  style={{
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    borderColor: "#ccc",
                                    height: 45,
                                    lineHeight: 2.5,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    paddingLeft: 13,
                                    paddingTop: 3,
                                    width: "50%",
                                  }}
                                >
                                  {file && file.name}
                                </div>
                                <Button
                                  type="button"
                                  className="ml-3"
                                  onClick={handleOpenDialog}
                                >
                                  Izberi .csv datoteko
                                </Button>
                                <Button
                                  className="ml-3"
                                  onClick={handleRemoveFile}
                                >
                                  Odstrani
                                </Button>
                              </aside>
                            )}
                          </CSVReader>
                        </Col>

                        <Col>
                          {csvData.length > 0 && (
                            <span className="text-muted">
                              Število zapisov v CSV datoteki: {csvData.length}{" "}
                            </span>
                          )}
                        </Col>

                        <Col>
                          <Button
                            className="float-right"
                            onClick={handleUvozData}
                          >
                            Uvozi
                          </Button>
                        </Col>
                      </>
                    )}
                  </>
                )}

                {databaseDataCount.st_id > 0 && (
                  <>
                    <Col>
                      <span className="primary-color">
                        <AiOutlineInfoCircle className="mb-1 mr-2" />
                        Število zapisov v volilnem imeniku:{" "}
                        {databaseDataCount.st_id}
                      </span>
                    </Col>
                    <Col>
                      <span className="float-right text-muted">
                        <AiOutlineQuestionCircle className="mb-1 mr-2" />
                        Za ponovni uvoz podatkov je potrebno obstoječe podatke
                        izbrisati
                      </span>
                    </Col>
                  </>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col className="main-container-text-small">Korak 2</Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col lg={6}>
                  {" "}
                  Priprava volilnega imenika - šifriranje podatkov{" "}
                  {loadingHash && (
                    <>
                      <Spinner animation="grow" className="mr-3 ml-3" />
                      <span>
                        <strong>
                          {numberOfHashed}/{databaseDataCount.st_id}
                        </strong>
                      </span>
                    </>
                  )}{" "}
                  {loadingSuccesHash && (
                    <>
                      <span>
                        <strong className="main-container-tekst__success">
                          {" "}
                          - Uspešno izvedeno
                        </strong>
                      </span>
                      <span className="ml-2 main-container-icon__success">
                        <AiOutlineCheckCircle />
                      </span>
                    </>
                  )}
                  {errorHash && (
                    <>
                      <span className="main-container-tekst__error">
                        - Prišlo je do napake pri šifriranju podatkov
                      </span>
                      <span className="ml-2 main-container-icon__error">
                        <AiOutlineExclamationCircle />
                      </span>
                    </>
                  )}
                </Col>

                {databaseDataCount.st_hash > 0 && (
                  <Col lg={4}>
                    <span className="primary-color">
                      <AiOutlineInfoCircle className="mb-1 mr-2" />
                      Število volilcev z šifriranimi podatki:{" "}
                      {databaseDataCount.st_id}
                    </span>
                  </Col>
                )}
                {databaseDataCount.st_id === 0 && (
                  <Col>
                    <span className="float-right text-muted">
                      <AiOutlineQuestionCircle className="mb-1 mr-2" />
                      Volilni imenik ne obstaja. Najprej ga je potrebno uvoziti
                      (Korak 1)
                    </span>
                  </Col>
                )}
                {databaseDataCount.st_id > 0 && (
                  <Col>
                    {displayHashButton && (
                      <Button className="float-right" onClick={handleAddHash}>
                        Sproži
                      </Button>
                    )}
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col className="main-container-text-small">Korak 3</Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col>
                  {" "}
                  Izvoz volilnega imenika
                  {hashedTableDataError && (
                    <>
                      <span className="main-container-tekst__error">
                        - Prišlo je do napake pri izvozu tabele
                      </span>
                      <span className="ml-2 main-container-icon__error">
                        <AiOutlineExclamationCircle />
                      </span>
                    </>
                  )}
                  {!hashedTableDataError && (
                    <>
                      {showDownloadFileButton && (
                        <CSVDownloader
                          data={hashedTableData}
                          type="button"
                          filename={"izvoz_simplyvoting" + id}
                          className="btn btn-success ml-4"
                          bom={true}
                        >
                          Prenesi simplyvoting csv{" "}
                          <span className="ml-1">
                            <FaFileCsv />
                          </span>
                        </CSVDownloader>
                      )}
                      {showDownloadFileButtonKontrolni && (
                        <Button
                          onClick={exportToXlsx}
                          className="main-container-button__success ml-4"
                        >
                          Prenesi kontrolni izvoz
                          <span className="ml-1">
                            <SiMicrosoftexcel />
                          </span>
                        </Button>
                      )}
                    </>
                  )}
                </Col>
                {databaseDataCount.st_hash > 0 && (
                  <>
                    <Col>
                      <Button
                        className="float-right"
                        onClick={handleExportCSVsimplyvoting}
                      >
                        Simplyvoting izvoz
                      </Button>
                      <Button
                        className="float-right mr-3"
                        onClick={handleExportCSVKontrolni}
                      >
                        Kontrolni izvoz
                      </Button>
                    </Col>
                  </>
                )}

                {databaseDataCount.st_hash === 0 && (
                  <Col>
                    <span className="float-right text-muted">
                      <AiOutlineQuestionCircle className="mb-1 mr-2" />
                      Volilni imenik je potrebno najprej šifrirati (korak 2)
                    </span>
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col className="main-container-text-small">Dodatno</Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col lg={9}> Pridobivanje podatkov za enega volilca </Col>
                <Col lg={2}>
                  <Form.Control
                    type="number"
                    placeholder="ID"
                    value={idVolilec}
                    onChange={(e) => {
                      setHashedId("");
                      setIdVolilec(e.target.value);
                    }}
                  />
                </Col>
                <Col lg={1}>
                  <Button className="float-right" onClick={handleGetUserData}>
                    Pridobi
                  </Button>
                </Col>
              </Row>
              {hashedId && (
                <Row className="mt-5 d-flex align-items-center">
                  <Col lg={2}></Col>
                  <Col lg={10}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Col lg={2}>Šifriran ID: </Col>
                          <Col lg={9}> {hashedId} </Col>
                          <Col lg={1}>
                            <Button
                              className="float-right main-container-navigation-button"
                              onClick={() => {
                                navigator.clipboard.writeText(hashedId);
                              }}
                            >
                              Kopiraj v odložišče
                            </Button>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col className="main-container-text-small">Dodatno</Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col> Brisanje volilnega imenika </Col>
                <Col>
                  <Button
                    className="float-right btn btn-danger"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Brisanje volilnega imenika
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <>
          <Modal
            show={showModal}
            onHide={() => {
              setShowModal(false);
            }}
          >
            <Modal.Body>Želite izbrisati podatke za {volitveTitle}</Modal.Body>
            <Modal.Footer>
              <Button
                className="btn btn-danger"
                variant="secondary"
                onClick={handleDataDelete}
              >
                Izbriši
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Ne
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Row>
      <Row className="mb-2">
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col className="main-container-text-small">Dodatno</Col>
              </Row>
              <Row className="d-flex align-items-center">
                <Col> Poročilo revizijska sled </Col>
                <Col>
                  {revisionData.length > 0 && (
                    <Button
                      className="float-right ml-2"
                      onClick={() => setRevisionData([])}
                    >
                      Zapri
                    </Button>
                  )}
                  <Button
                    className="float-right btn btn-warning"
                    onClick={handleGetRevisionData}
                  >
                    Prikaži
                  </Button>
                </Col>
              </Row>
              {revisionData.length > 0 && (
                <Row className="mt-5">
                  <Col lg={2}></Col>
                  <Col lg={10}>
                    <Table bordered hover size="sm">
                      <thead>
                        <tr>
                          <th>Dogodek</th>
                          <th>Uporabnik</th>
                          <th>Čas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {revisionData.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td>{value.dogodek}</td>
                              <td>{value.upn}</td>
                              <td>
                                {" "}
                                {new Date(value.timestamp).toLocaleString()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminVolitveControlPanel;
