import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { getUserName } from "./adalConfig";

/*Component Import*/
import Header from "./components/Header";
import AuthorisationComponent from "./components/AuthorisationComponent";

/*React-bootstrap components */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const App = () => {
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    if (
      getUserName() === process.env.REACT_APP_ADMIN_1 ||
      getUserName() === process.env.REACT_APP_ADMIN_2 ||
      getUserName() === process.env.REACT_APP_ADMIN_3 ||
      getUserName() === process.env.REACT_APP_ADMIN_4 ||
      getUserName() === process.env.REACT_APP_ADMIN_5
    ) {
      setUserRole("admin");
    } else {
      setUserRole("uporabnik");
    }
  }, []);

  return (
    <Router>
      <Container fluid>
        <Row>
          <Header />
        </Row>
        <Row className="main-container">
          <AuthorisationComponent role={userRole} />
        </Row>
      </Container>
    </Router>
  );
};

export default App;
